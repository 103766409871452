import React from "react";
import LayoutExamples from "../../../layouts/LayoutExamples";
import Seo from "../../../components/seo";
import { Row, Col, Divider } from "antd";
import ButtonsNavigation from "../../../components/ui/ButtonsNavigation.js";
import ChartTestclientPsych from "../../../client/testclient-psych/chart/Chart.js";
import ChartTestcleintPsychDepressiviteit from "../../../client/testclient-psych/json/depressiviteit.json";

const ExamplePremiumDepressiviteit = () => (
  <LayoutExamples>
    <Seo
      title="Premium example | Despressiviteit"
      description="inFlow helps professional organisations create interactive decision-making tools such as flow charts, decision trees or algorithms. Improve your process and increase engagement with our world-class tools."
    />
    <ChartTestclientPsych
      entry={ChartTestcleintPsychDepressiviteit}
      firstNodeId="node1"
    />
    <Divider dashed style={{ margin: "0" }} />
    <Row
      justify="center"
      align="middle"
      style={{ backgroundColor: "#f8f9fa", padding: "48px 0" }}
    >
      <Col>
        <ButtonsNavigation
          linkNext="Next example"
          linkNextTo="/examples/medical/example-verdenking-op-longembolie"
        />
      </Col>
    </Row>
  </LayoutExamples>
);

export default ExamplePremiumDepressiviteit;
